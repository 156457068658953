<!-- eslint-disable no-nested-ternary -->
<!-- eslint-disable no-nested-ternary -->
<!-- eslint-disable no-nested-ternary -->
<template>
  <div>
    <small class="text-danger pt-2">{{ error }}</small>
    <vue-good-table
      class="pt-2"
      :columns="columns"
      :rows="fees"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <template slot="loadingContent">
        <b-spinner
          label="Loading"
          type="grow"
        />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Quantity -->
        <span v-if="props.column.field === 'name'">
          <!-- {{ props.formattedRow[props.column.field] }} -->

          <div>{{ props.row.fee.name }}</div>
          <div
            v-if="props.row.fee.price > 0"
            class="text-secondary font-italics font-small-3"
          >
            ({{ Number(props.row.fee.price).toLocaleString() }} đ/{{ props.row.fee.unit }})
          </div>
        </span>
        <!-- Column: Quantity -->
        <span v-else-if="props.column.field === 'coefficient' ">
          <validation-provider
            #default="validationContext"
            :name="`${props.column.field} ${props.row.originalIndex}`"
            rules="required"
          >
<!-- 
            <spin-button
              v-model="props.row[props.column.field]"
              :step="0.1"
              :min="0"
              :default-value="props.row[props.column.field]"
              :error="validationContext.errors[0]"
              @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
            /> -->

            <number-input
                v-model="props.row['coefficient']"
                placeholder="Số lượng"
                :default-value="props.row['coefficient']"
                :error="validationContext.errors[0]"
                @input="changeCell(props.row['coefficient'], 'coefficient', props.row.originalIndex)"
              />

          </validation-provider>

        </span>

        <!-- Column: Billing Date -->
        <span v-else-if="props.column.field === 'billingDate'">
          <validation-provider
            #default="validationContext"
            :name="`Ngày tính phí ${props.row.originalIndex}`"
            rules="required"
          >
            <select-date
              v-model="props.row.billingDate"
              :default-value="props.row.billingDate"
              :error="validationContext.errors[0]"
              @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
            />

          </validation-provider>
        </span>

        <!-- Column: Meter -->
        <span v-else-if="props.column.field === 'meter'">
          <div v-if="props.row.fee.type.id === 1 || props.row.fee.type.id === 2">
            <span v-if="isTingTong && isTransfer">{{ props.row.meter.name }}</span>
            <validation-provider
              v-else
              #default="validationContext"
              :name="`Công tơ ${props.row.originalIndex}`"
              rules="required"
            >
              <select-meter
                v-model="props.row.meter"
                :append-to-body="true"
                :apartment="apartment"
                :room="room"
                :fee="props.row.fee"
                :default-value="props.row.meter"
                :error="validationContext.errors[0]"
                @input="changeCell(props.row['meter'], 'meter', props.row.originalIndex)"
              />

            </validation-provider>
          </div>

        </span>

        <!-- Column: Quantity -->
        <span v-else-if="props.column.field === 'quantity'">

          <div v-if="props.row.fee.type.id !== 1 && props.row.fee.type.id !== 2">

            <span v-if="props.row.fee && ['Người', 'Phòng', 'Giường', 'Kiosk'].includes(props.row.fee.unit) && autoCalculateQuantityByTenant">{{ props.row['quantity'] }}</span>
            <validation-provider
              v-else
              #default="validationContext"
              :name="`Số lượng ${props.row.originalIndex}`"
              rules="required"
            >

              <!-- <spin-button
                v-model="props.row['quantity']"
                :min="0"
                placeholder="Số lượng"
                :default-value="props.row['quantity']"
                :error="validationContext.errors[0]"
                @input="changeCell(props.row['quantity'], 'quantity', props.row.originalIndex)"
              /> -->
              
              <number-input
                v-model="props.row['quantity']"
                placeholder="Số lượng"
                :default-value="props.row['quantity']"
                :error="validationContext.errors[0]"
                @input="changeCell(props.row['quantity'], 'quantity', props.row.originalIndex)"
              />
          </validation-provider>
          </div>

        </span>

        <!-- Column: First Index -->
        <span v-else-if="props.column.field === 'firstIndex'">

          <div v-if="props.row.fee.type.id === 1 || props.row.fee.type.id === 2">
            <span v-if="isTingTong && isTransfer">{{ props.row.firstIndex }}</span>
            <validation-provider
              v-else
              #default="validationContext"
              :name="`Chỉ số đầu ${props.row.originalIndex}`"
              rules="required"
            >
              <number-input
                v-model="props.row['firstIndex']"
                placeholder="Chỉ số đầu"
                :default-value="props.row['firstIndex']"
                :error="validationContext.errors[0]"
                @input="changeCell(props.row['firstIndex'], 'firstIndex', props.row.originalIndex)"
              />

            </validation-provider>
          </div>

        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'price'">
          {{ Number(props.row.price).toLocaleString() }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <table-actions
            :name="t('Dịch vụ')"
            :disable-edit="true"
            @on-delete="onDeleteItem(props.row)"
          />
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="emptystate">
        <div class="text-center text-muted">
          {{ t('Không có bản ghi nào!') }}
        </div>
      </div>
    </vue-good-table>

  </div>

</template>

<script>
import { BSpinner } from 'bootstrap-vue';
import TableActions from '@/views/components/TableActions.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import SelectMeter from '@/views/components/SelectMeter.vue';
import SpinButton from '@/views/components/SpinButton.vue';
import { VueGoodTable } from 'vue-good-table';
import { ValidationProvider } from "vee-validate";
import { required } from "@validations";
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BSpinner,

    VueGoodTable,
    TableActions,
    NumberInput,
    SelectDate,
    SelectMeter,
    ValidationProvider,
    SpinButton,
  },
  props: {
    apartment: {
      type: Object,
      default: null,
    },
    room: {
      type: Object,
      default: null,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
    id: {
      type: Number,
      default: null,
    },
    isTransfer: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fees: this.rows,
      required,
    };
  },

  watch: {
    rows(val) {
      this.fees = val;
    },
  },
  methods: {
    changeCell(changedData, column, row) {
      if (changedData || column === 'quantity' || column === 'coefficient') {
        if (column === 'meter' && (!this.id || this.id <= 0) && !this.fees[row].id) {
        // Trong TH chon cong to
          this.fees[row].firstIndex = changedData ? changedData.lastIndex : 0;
        }

        this.fees[row][column] = changedData;

        this.onChange();
      }
    },
    onDeleteItem(item) {
      const remainData = this.fees.filter(fee => fee.fee.id !== item.fee.id);
      this.fees = remainData;
      this.onChange();
    },
    onChange() {
      this.$emit('input', this.fees);
    },
  },
  setup() {
    // Table Handlers
    const { t } = useI18nUtils();
    const userConfiguration = JSON.parse(localStorage.getItem('userConfiguration'));
    const autoCalculateQuantityByTenant = !!(userConfiguration && userConfiguration.autoCalculateQuantityByTenant);
    const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';
    const columns = [
      {
        label: t('Dịch vụ'),
        field: 'name',
        sortable: false,
      },
      {
        label: t('Công tơ'),
        field: 'meter',
        sortable: false,
        width: "200px",
      },
      {
        label: t('Chỉ số đầu'),
        field: 'firstIndex',
        sortable: false,
        width: "110px",
      },
      {
        label: t('Số lượng'),
        field: 'quantity',
        sortable: false,
        width: "110px",
      },
      {
        label: t('Hệ số'),
        field: 'coefficient',
        sortable: false,
        width: "100px",
        hidden: isTingTong === true ? isTingTong : userConfiguration ? !userConfiguration.enableCoefficient : false,
      },

      {
        label: t('Ngày tính phí'),
        field: 'billingDate',
        sortable: false,
        width: "140px",
        hidden: userConfiguration ? !userConfiguration.autoCalculateCoefficientByDate : false,
      },
      {
        label: t('Thao tác'),
        field: 'action',
        tdClass: 'text-center',
        sortable: false,
        width: "90px",
      },
    ];

    return {
      columns,
      autoCalculateQuantityByTenant,
      t,
      isTingTong,
    };
  },
};
</script>

<style lang="scss">
.vs__dropdown-menu {
  z-index: 1041 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.contract-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 120px !important;

            &:first-child {
              min-width: 50px !important;
            }

            &:nth-child(3) {
               min-width: 180px !important;
            }
            &:nth-child(4) {
               min-width: 200px !important;
            }
            &:nth-child(10) {
               min-width: 200px !important;
            }
          }
        }
      }
    }
}
</style>
